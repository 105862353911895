<template>
    <div>
        <strong>Item Batch Upload</strong>
        <br>
        <div class="form-group">
            <label>Batch #</label>
            <input type="number" class="form-control" v-model.number="state.referenceID" />
        </div>
        <div class="form-group">
            <label>File Type</label>
            <select v-model="state.fileTypeID" class="form-control">
                <option :value="this.$cdiVars.FILE_TYPE_ITEM_BATCH_FL">Plasma Batch File</option>
                <option :value="this.$cdiVars.FILE_TYPE_SORTING_STICKERS">Sorting Stickers</option>
            </select>
        </div>
    </div>
</template>

<script>
    import { fileUploadStore } from "@/store/FileUpload.store";

    export default {
        name: "ItemBatch",
        data() {
            return {
                state: fileUploadStore.state
            }
        },
        created() {
            fileUploadStore.initialize(this.$cdiVars.FILE_TYPE_ITEM_BATCH_FL);
        },
        mounted() {
            if (!this.state.referenceID) {
                fileUploadStore.blankReferenceID();
            }
        }
    }
</script>

<style scoped>

</style>